export const ONE_CLICK_DIMENSIONS = {
  WIDTH: '280px',
  HEIGHT: '320px',
  MARGIN: '12px'
};

export const ONE_CLICK_ICON_DIMENSIONS = {
  MAX_HEIGHT: '76px',
  MAX_WIDTH: '76px',
  HORIZONTAL_MAX_HEIGHT: '68px',
  HORIZONTAL_MAX_WIDTH: '68px'
};
