import { Button, mediaQueries, style } from '@do/walrus';
import styled from 'styled-components';
import { ONE_CLICK_DIMENSIONS } from '../components/OneClickResource/constants';
import { CATEGORY_GRID_BREAKPOINTS, SIDENAV_WIDTH } from '../utils/constants';

export const PageContainer = styled.div`
  max-width: ${style.viewports.max}px;
  margin: 0 auto;
  padding: 0 ${style.vars.space['3']};

  ${mediaQueries.largeMin} {
    padding: 0 78px;
  }
`;

export const PageHeader = styled.h2`
  font-size: 28px;
  padding-top: 28px;
  padding-bottom: 28px;

  ${mediaQueries.largeMin} {
    font-size: 38px;
    padding-top: 64px;
    padding-bottom: 45px;
  }
`;

export const ImageTitleTextGroup = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-flow: column;
  align-items: center;

  ${mediaQueries.mediumMin} {
    display: block;
  }
`;

export const WhiteButton = styled(Button)`
  background: ${style.colors.white};
  border-color: ${style.colors.blue.dark};
  color: ${style.colors.blue.dark};

  &:focus {
    color: ${style.colors.blue.dark};

    &:hover {
      color: ${style.colors.white};
    }
  }

  &:hover {
    background: ${style.colors.blue.dark};
  }
`;

/*
  overwrite h1 and h2 to display like h3s
*/
export const HtmlField = styled.div`
  h1,
  h2 {
    border-bottom: 0 none;
    margin-top: ${style.vars.space['4']};
    font-weight: 600;
    font-size: 1.3rem;
  }
  h3 {
    font-size: 1rem;
  }

  ol,
  ul {
    padding-left: ${style.vars.space['6']};
  }

  p,
  li {
    color: ${style.colors.shadesOfGrey['8']};
  }
`;

export const SolutionPageContainer = styled.div`
  margin: 0 auto;

  @media (${CATEGORY_GRID_BREAKPOINTS.TwoCards}) {
    width: ${parseInt(ONE_CLICK_DIMENSIONS.WIDTH, 10) * 2 +
    parseInt(ONE_CLICK_DIMENSIONS.MARGIN, 10) * 4}px;
  }

  @media (${CATEGORY_GRID_BREAKPOINTS.ThreeCards}) {
    width: 100%;
  }
`;

export const SideNavPageWrapper = styled.div`
  margin-top: 40px;
  display: flex;
`;

export const SideNavPageContent = styled.div`
  width: 100%;

  @media (${CATEGORY_GRID_BREAKPOINTS.ThreeCards}) {
    width: calc(100% - ${SIDENAV_WIDTH}px);
    padding-left: 50px;
  }
`;

export const SelectContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;

  @media (${CATEGORY_GRID_BREAKPOINTS.ThreeCards}) {
    display: none;
  }
`;

export const Select = styled.select`
  width: 100%;
  margin-bottom: 12px;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' fill='rgba(51, 51, 51, 0.75)' width='12' height='12'%3E%3Cpath d='M31.296 7.68c-.256-.32-.704-.512-1.216-.512-.448 0-.896.192-1.216.512L16 20.736 3.2 7.68c-.256-.32-.704-.512-1.216-.512-.448 0-.896.192-1.216.448S.256 8.32.256 8.768c0 .512.192.96.512 1.28L14.72 24.32c.32.32.704.512 1.152.512.256 0 .512-.064.768-.192.128-.064.256-.128.384-.128l.128-.064 14.016-14.336c.768-.64.704-1.728.128-2.432z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position: calc(100% - 12px) 16px;
  transition: none;
`;

// defaults to div, use the "as" property for a different tag
export const TruncatedText = styled.div<{
  lines: number;
  lineHeight?: number;
}>`
  overflow: hidden;
  max-height: ${({ lines, lineHeight }) =>
    lines * (lineHeight || parseFloat(style.vars.lineHeight))}em;
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  display: -moz-box; /* stylelint-disable-line value-no-vendor-prefix */
  -webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */
  -moz-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */
  -webkit-line-clamp: ${({ lines }) =>
    lines}; /* stylelint-disable-line property-no-vendor-prefix */
`;
